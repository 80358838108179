.spinner {
  align-self: center;
}

a.link {
  display: inline-flex;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    /* make underline visible */
    padding: 2px 0;
  }
}

.table {
  min-width: 600px;
  /* hacky way to make horizontal scrollbar visible, proper way would be to fix layout */
  margin-bottom: 53px;

  :global {
    td.rc-table-cell,
    th.rc-table-cell {
      padding: var(--ads-v2-spaces-5) var(--ads-v2-spaces-2);
      white-space: nowrap;
    }

    td.rc-table-cell:nth-child(3) {
      white-space: unset;
    }

    .rc-table-cell:first-child {
      padding-left: var(--ads-v2-spaces-3);
    }

    .rc-table-cell:last-child {
      padding-right: var(--ads-v2-spaces-3);
    }
  }
}
